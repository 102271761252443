<template>
  <div>
    <div class="form-body" style="padding-top: 46rem">
      <el-form ref="form" label-width="150rem">
        <div style="padding-left: 36rem">
          <h4>目标信息</h4>
          <el-form-item label="名称:">
            <p>{{ detailsData.name }}</p>
          </el-form-item>
          <el-form-item label="考试名称:">
            <p>{{ detailsData.exam_name }}</p>
          </el-form-item>
          <el-form-item label="校区:">
            <p>{{ detailsData.school_name }}</p>
          </el-form-item>
          <el-form-item label="年级:">
            <p>{{ detailsData.grade_name }}</p>
          </el-form-item>
          <el-form-item label="开始填写时间:" >
            <p>{{ detailsData.start_at }}</p>
          </el-form-item>
            <el-form-item label="结束填写时间:">
              <p>{{ detailsData.end_at }}</p>
            </el-form-item>
            <el-form-item label="创建人:">
              <p>{{ detailsData.creator }}</p>
            </el-form-item>
            <el-form-item label="提交时间:">
              <p>{{ detailsData.created_at }}</p>
            </el-form-item>
          <h4>关联考试</h4>
          <el-form-item label="目标考试关联">
            <el-select :popper-append-to-body="true" v-model="exam_id" @change="onChange">
              <el-option v-for="item in examConfig" :key="item.exam_id" :label="item.name" :value="item.exam_id" />
            </el-select>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="footer-button">
      <el-button type="primary" @click="$router.back()">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
	_config:{"route":{"path":"details","meta":{"title":"查看"}}},
  data() {
    return {
      detailsData: '',
      exam_id: '',
      exam_id_old: '',
      examConfig: []
    }
  },
  created(){
    this.getData()
  },
  methods: {
    getData(){
      this.$_axios2.get('api/student/goal-setting/' + this.$route.query.id).then(res => {
        let data = res.data.data
        this.detailsData = data;
        this.exam_id = data.target_exam_id
        this.exam_id_old = data.target_exam_id

        this.$_axios2.get('api/student/target/get-exam?exam_type=1&school_id=' + data.school_id + '&grade_id=' + data.grade_id).then(_res => {
          this.examConfig = _res.data.data
        })
      })
    },

    onChange(id){
      if(this.exam_id_old && this.exam_id_old !== id){
        let str;
        this.examConfig.forEach(item => {
          if(id === item.exam_id) str = item.name
        })
        this.$confirm(`是否确认将<br/><span style="color: #1d2088">${this.detailsData.target_exam_name}</span><br/>替换成<br/><span style="color: #1d2088">${str}</span> ？`, {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确认',
          cancelButtonText: '点错了',
          type: 'warning'
        }).then(async () => {
          this.func()
        }).catch(() => {
          this.exam_id = this.exam_id_old
        })
      }else{
        this.func()
      }
    },

    func(){
      const loading = this.$loading({
        lock: true,
        text: '请求中',
        customClass: 'img-upload-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$_axios2.post('api/student/goal-setting/bind-target', {
        id: this.detailsData.id,
        target_exam_id: this.exam_id
      }).then(res => {
        if(res.data.status === 0){
          this.exam_id_old = this.exam_id;
          this.$message({
            message: '关联成功！',
            type: 'success'
          });
          this.examConfig.forEach(item => {
            if(item.exam_id == this.exam_id){
              this.detailsData.target_exam_name = item.name
            }
          })
        }else{
          this.exam_id = this.exam_id_old
        }
      }).catch(err => {
        this.exam_id = this.exam_id_old
      }).finally(() => {
        loading.close()
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.knowledge {
  margin-top: 24rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .knowledge_box {
    display: flex;
    padding-top: 12rem;
    height: 60rem;
    background-color: #f4f4f4;
  }
  .add_knowledge {
    display: flex;
    padding-left: 40rem;
  }
}

h4{
  font-size: 18rem;
  color: #333;
  font-weight: bold;
  margin-bottom: 30rem;
}

@media only screen and (max-width: 1500rem) {
  .knowledge {
    width: 1200rem !important;
  }
}
</style>
